<template>
  <div id="notification-basic-wrapper">
    <div
      v-if="notificationState"
      aria-live="assertive"
      class="z-20 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      <div
        v-for="n in notificationState.notifications"
        :key="n.id"
        class="w-full flex flex-col items-center space-y-4 sm:items-end"
      >
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="n.show"
            class="z-20 max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div v-if="n.type" class="flex-shrink-0">
                  <Icon v-if="n.type === 'success'" name="heroicons:check-circle" class="h-6 w-6 text-green-400" aria-hidden="true" />
                  <Icon v-if="n.type === 'error'" name="heroicons:exclamation-circle" class="h-6 w-6 text-red-400" aria-hidden="true" />
                  <Icon v-if="n.type === 'error'" name="heroicons:information-circle" class="h-6 w-6 text-blue-400" aria-hidden="true" />
                  <Icon v-if="n.type === 'error'" name="heroicons:exclamation-triangle" class="h-6 w-6 text-orange-400" aria-hidden="true" />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p v-if="n.title" class="text-sm font-medium text-gray-900">
                    {{ n.title }}
                  </p>
                  <p v-if="n.message" class="mt-1 text-sm text-gray-500">
                    {{ n.message }}
                  </p>
                </div>
                <div class="ml-4 flex-shrink-0 flex">
                  <button
                    class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    @click="notificationState.removeNotification(n)"
                  >
                    <span class="sr-only">Close</span>
                    <Icon name="heroicons:x-mark" class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { useNotificationState } from '~/stores/notifications'

// Define props
// const props = defineProps({
//     align: {
//         type: String,
//         required: false,
//         default: 'center'
//     },
//     message: {
//         type: String,
//         required: false,
//         default: 'Loading...'
//     },
//     hints: {
//         type: Array,
//         required: false,
//         description: 'An array of strings to be displayed as bullet points to guide the user on how to resolve the error.',
//         default: []
//     },
// })

// const { message, hints, align } = props

const notificationState = useNotificationState()

</script>
